import './App.css';
import UnityGame from './components/Unity.js';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Replace the original console.log with a function that does nothing
console.log = function() {};

function App() {
  return (
    <>
      <CookiesProvider>
      <main>
        <Router>
          <Routes>
            <Route exact path="/" element={<UnityGame />} />
          </Routes>
        </Router>
        </main>
       </CookiesProvider>
    </>
  );
}

export default App;
